import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import logo from '../img/logo.png'

const Navbar = () => {
  const [active, setActive] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      allStrapiNewfAbout {
        edges {
          node {
            title
            slug
          }
        }
      }
      allStrapiVhwAbout {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `)

  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">

        <Link to="/" className="navbar-item">
          <img src={logo} height="52" alt="van het Wilmershof"/>
        </Link>

        <div
          role="button"
          className={`navbar-burger burger ${active ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navMenu"
          onClick={() => setActive(!active)}
          onKeyDown={() => setActive(!active)}
          tabIndex={0}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div
        id="navMenu"
        className={`navbar-menu ${active ? 'is-active' : ''}`}
      >
        <div className="navbar-end">
          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/blog">
              Blog
            </Link>
            <div className="navbar-dropdown">
              <Link className="navbar-item" to="/blog-archive">
                Blog archief
              </Link>
            </div>
          </div>

          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/newfoundlander">
              De Newfoundlander
            </Link>
            <div className="navbar-dropdown">
              {data.allStrapiNewfAbout.edges.map((newfAboutPage, i) => {
                return(
                  <Link
                    key={i}
                    to={`/newfoundlander/${newfAboutPage.node.slug}`}
                    className="navbar-item"
                  >
                    {newfAboutPage.node.title}
                  </Link>
                )
              })}
            </div>
          </div>

          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/over-ons">
              Over Ons
            </Link>
            <div className="navbar-dropdown">
              {data.allStrapiVhwAbout.edges.map((vhwAboutPage, i) => {
                return(
                  <Link
                    key={i}
                    to={`/over-ons/${vhwAboutPage.node.slug}`}
                    className="navbar-item"
                  >
                    {vhwAboutPage.node.name}
                  </Link>
                )
              })}
            </div>
          </div>

          <Link className="navbar-item" to="/contact">
            Contact
          </Link>

        </div>
      </div>
    </nav>
  )
}

export default Navbar
