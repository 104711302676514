import React, { Fragment } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Hero from './Hero'

import './mystyles.scss'

const Layout = ({ pageData, title, children, crumbLabel }) => {
  return (
    <Fragment>
      <Navbar />
      <Hero pageData={pageData} />
      <section className="section">
        {children}
      </section>
      <Footer />
    </Fragment>
  )
}

export default Layout
