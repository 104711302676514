import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

const NotFoundPage = ({ data }) => (
  <Layout pageData={data.strapiHomepage}>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    strapiHomepage {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
  }
`