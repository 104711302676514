import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Club {
      logo1: file(absolutePath: { regex: "/img/logo-NNFC.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            height: 80
          )
        }
      }
      logo2: file(absolutePath: { regex: "/img/logo_lid-van-rvb_horizontaal_rgb_basis.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            height: 80
          )
        }
      }
    }
  `)

  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <nav className="level">


            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Wij zijn lid van:</p>
                <div>
                  <a href="https://www.nnfc.nl/">
                  <GatsbyImage
                    image={data.logo1.childImageSharp.gatsbyImageData}
                    alt="NNFC Logo"
                  />
                  </a>
                </div>
                <div>
                  <a href="https://www.houdenvanhonden.nl/">

                    <GatsbyImage
                      image={data.logo2.childImageSharp.gatsbyImageData}
                      alt="houdenvanhonden Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Wij zijn te vinden op:</p>
                <div>
                  <a href="https://www.facebook.com/VanHetWilmershof/">
                    facebook
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/vanhetwilmershof/">
                    Instagram
                  </a>
                </div>
              </div>
            </div>
        </nav>

        <div className="content">
          © {new Date().getFullYear()}, van het Wilmershof
        </div>
      </div>

    </footer>
  )
}

export default Footer
