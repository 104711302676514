import React from "react"
import { Link } from 'gatsby'
import Navbar from './Navbar'
import logo from '../img/logo.svg'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Hero = ({pageData}) => {
  const image = getImage(pageData.cover.localFile)
  console.log("Hero.pageData", pageData);

  return (
    <section className="hero is-medium is-primary has-background">
      <GatsbyImage
        className="hero-background is-transparent"
        image={image}
        alt={pageData.title}
      />
      <div className="hero-body">
        <div className="container has-text-centered">
          <div>
            <p className="title is-3 px-2 mb-1 is-tranparent is-inline-block">
              {pageData.title}
            </p>
          </div>
            <p className="subtitle is-5 px-2 is-inline-block">
              {pageData.subtitle}
            </p>
        </div>
      </div>
    </section>
  )
} 

export default Hero
